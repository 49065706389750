// @use '@/styles/bootstrap-custom/variables' as *;
@use '@/styles/bootstrap-custom/override' as *;
// ORDER:
// // @use '../../node_modules/bootstrap/scss/bootstrap.scss' as *;
// @use '~bootstrap/scss/functions' as *;
// @use '~bootstrap/scss/variables' as *;
// @use '~bootstrap/scss/mixins/breakpoints' as *;

@use '@/styles/use-bootstrap' as *;
@use '@/styles/util/vars' as *;
@use '@/styles/util/mixins' as *;
// ORDER:
// @use '@/styles/util/fonts' as *;
// @use '@/styles/util/functions' as *;

// Import library stylesheets

// // NOT WORKING
// // @use '/node_modules/react-typist/dist/Typist.css' as *;

.Typist .Cursor {
  display: inline-block;
}
.Typist .Cursor--blinking {
  opacity: 1;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Initialization

* {
  box-sizing: border-box;
}

html,
body {
  cursor: default;
  // font-family: $font-family-base;
  // font-size: 20px;
  // font-weight: $fontWeightDefault;
  margin: 0;
  padding: 0;
  -webkit-user-drag: none;
  user-select: none;
}

// html,
// body,
#__next {
  height: 100%;
  width: 100%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-normal;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  border: 0;
  box-shadow: none !important;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
}

button:hover,
button:focus,
button:focus-visible {
  border: 0;
  box-shadow: none !important;
  outline: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

em {
  font-style: normal;
}

.container {
  padding: 0 1.5rem;

  @include media-breakpoint-up(lg, $grid-breakpoints) {
    padding: 0 3rem;
  }
}

// Utility

.borderNone {
  border: $borderNone !important;
}

// TODO: add auto-copy feature (with popup message)
// .selectable {
//   cursor: grab;
//   -webkit-user-drag: unset;
//   transition: all 0.2s ease-out;
//   user-select: all;

//   &:hover {
//     color: lighten($defaultColor, 30%);
//   }

//   &.light:hover {
//     color: darken($lightColor, 20%);
//   }
// }

.fitFullHeight {
  height: 100vh;
}

.fullHeight {
  min-height: 100vh;
}

.paddingTopNavbar {
  padding-top: $navbarHeight;
}

@include media-breakpoint-down(md, $grid-breakpoints) {
  br.mdr {
    display: none;
  }
}

@include media-breakpoint-up(md, $grid-breakpoints) {
  br.md {
    display: none;
  }
}

@include media-breakpoint-down(md, $grid-breakpoints) {
  span.md {
    display: none;
  }
}

// Global (deprecated)

.fixedBackground {
  video {
    object-fit: contain;
    transform: translateX(-50%);

    @include media-breakpoint-up(sm, $grid-breakpoints) {
      object-fit: cover;
      transform: unset;
    }
  }
}

.em {
  font-weight: $fontWeightBold3;
}

.em2 {
  font-weight: $fontWeightBold4;
}

.map-container {
  height: 400px;
  width: 100%;
}

.map-ref {
  height: 100%;
}
