// REF: https://codepen.io/antonioio/pen/BeVxaN?editors=1100

@use '@/styles/use-bootstrap' as *;

@use '@/styles/util/vars' as *;

// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/mixins/breakpoints';
// @import '~bootstrap/scss/variables';

// Override

.modal-open #__next > * {
  filter: blur(3px);
}

.shadow {
  box-shadow: inherit !important;
}

// .container,
// .container-fluid {
//   max-width: $containerMaxWidth;
// }

// header {
//   .navbar {
//     .navbar-toggler {
//       padding-left: 0;
//       padding-right: 0;

//       .icon-bar {
//         border-radius: 1px;
//         display: block;
//         height: 2px;
//         margin-top: 4px;
//         transition: all 0.2s;
//         width: 22px;

//         &:nth-of-type(1) {
//           transform: rotate(45deg);
//           transform-origin: 10% 10%;
//         }

//         &:nth-of-type(2) {
//           opacity: 0;
//           filter: alpha(opacity = 0);
//         }

//         &:nth-of-type(3) {
//           transform: rotate(-45deg);
//           transform-origin: 10% 90%;
//         }
//       }

//       &.collapsed .icon-bar {
//         &:nth-of-type(1) {
//           transform: rotate(0);
//         }

//         &:nth-of-type(2) {
//           filter: alpha(opacity = 100);
//           opacity: 1;
//         }

//         &:nth-of-type(3) {
//           transform: rotate(0);
//         }
//       }
//     }

//     .navbar-nav {
//       .dropdown-toggle::after {
//         display: none;
//       }

//       .nav-item {
//         @include media-breakpoint-up(md, $grid-breakpoints) {
//           &:last-of-type {
//             padding-right: 1.5rem;
//           }
//         }
//       }

//       .nav-link {
//         text-transform: uppercase;
//         transition: $navItemTransition;

//         font-size: 1.5rem;
//         font-weight: $fontWeightDefault;

//         @include media-breakpoint-up(md, $grid-breakpoints) {
//           font-size: 1rem;
//           // font-weight: 400;
//         }

//         // &:hover {
//         //   color: $defaultColor;
//         // }
//       }
//     }
//   }

//   .nav-link,
//   .navbar-brand svg {
//     cursor: pointer;
//     z-index: 850;
//   }
// }

// // Custom

// .navbar {
//   .dropdown {
//     .dropdown-menu {
//       background: rgba(255, 255, 255, 0);
//       border-radius: 2px;
//       left: 62%;
//       padding: 20px;
//       position: relative;
//       text-align: center;
//       top: 100%;
//       transform: translate(-50%, 0);

//       @include media-breakpoint-down(md, $grid-breakpoints) {
//         padding-top: 0;
//       }

//       .dropdown-item:hover,
//       .dropdown-item:focus {
//         color: unset;
//         background-color: unset;
//       }
//     }

//     &:hover .dropdown-menu {
//       display: block;
//       margin-top: 0; // remove the gap so it doesn't close
//     }
//   }
// }

// .navbar-expand-md {
//   z-index: 99;
//   background: $bgLightColor;
//   padding: 0;
//   .container {
//     .navbar-toggler {
//       display: block;
//     }
//   }
// }

// .navbar-collapse {
//   @include media-breakpoint-down(md, $grid-breakpoints) {
//     background: $bgLightColor;
//     transition: opacity 0.2s ease-out;
//     left: 0;
//     top: 0;
//     opacity: 0;
//     position: fixed;
//   }

//   .navbar-nav {
//     @include media-breakpoint-down(md, $grid-breakpoints) {
//       position: absolute;
//       top: $navbarTopMobile;
//       width: 100%;
//     }

//     .nav-item {
//       opacity: 0;
//       padding: 0;
//       transition: opacity 1s ease-out;

//       @include media-breakpoint-up(md, $grid-breakpoints) {
//         opacity: 1;
//       }

//       .dropdown-menu {
//         border: 0;
//         border-radius: 0;
//       }

//       .nav-link {
//         text-align: center;
//       }
//     }
//   }

//   &.show {
//     min-height: 100vh;
//     opacity: 1;
//     z-index: 9;

//     .navbar-nav {
//       .nav-item {
//         opacity: 1;

//         &:nth-of-type(1) {
//           transition-delay: $navItemDelayBase + $navItemDelayAdd * 1;
//         }

//         &:nth-of-type(2) {
//           transition-delay: $navItemDelayBase + $navItemDelayAdd * 2;
//         }

//         &:nth-of-type(3) {
//           transition-delay: $navItemDelayBase + $navItemDelayAdd * 3;
//         }

//         &:nth-of-type(4) {
//           transition-delay: $navItemDelayBase + $navItemDelayAdd * 4;
//         }

//         &:nth-of-type(5) {
//           transition-delay: $navItemDelayBase + $navItemDelayAdd * 5;
//         }

//         &:nth-of-type(6) {
//           transition-delay: $navItemDelayBase + $navItemDelayAdd * 6;
//         }
//       }
//     }
//   }
// }
